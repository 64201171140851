import './App.css';
import React, {useEffect} from 'react';
import Login from './components/core/Login/Login';
import WalletPage from './components/core/customerWallet/index';
import Dashboard from './components/core/Dashboard/Dashboard';
import { store, persistor } from './store';
import { URL_LIST } from './constants/apiList';
import { useHistory } from "react-router";
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { getBrand } from './helpers';
import axios from 'axios';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect
} from "react-router-dom";
import { getBrandConfig, BRAND_CONFIG_KEYS } from './constants/brandConfig';
import { Helmet } from 'react-helmet';

function App() {
  const history = useHistory();
  const applyLoginCheck = async () => {
    if (window && window.location && window.location.search) {
      const query = new URLSearchParams(window.location.search);
      const authToken = query.get('t');
      if (authToken) {
        localStorage.setItem("token", authToken);
      }
    }
    const token = localStorage.getItem("token");
    if(!token || token === "null") {
      store.dispatch({ type: "CLEAR", payload: null });
      return;
    };
    try {
      const brand = getBrand();
      store.dispatch({ type: "SET_BRAND", payload: {brand} });
      axios.defaults.headers.common["Authorization"] = token;
      const res = await axios.post(URL_LIST.VERIFY_TOKEN, {});            
      if(res.data.success) {
        store.dispatch({ type: "USER", payload: res.data.userInfo });
        console.log("HEY YOU ARE GOOD TO GO");
        if (window.location.pathname === '/login' || window.location.pathname === '/') {
          window.location.href = '/dashboard';
        }
      }            
      else {
        store.dispatch({ type: "CLEAR", payload: null });
        // localStorage.setItem("token", null);
        window.location.href = '/login';
      }
    }
    catch(err) {
      console.log("ERROR IN /verifyToken", err);
      return false;
    }
  } 
  useEffect(applyLoginCheck,[]);
  const AdminRoute = ({ component: Component, ...rest }) => {
    const user = store.getState().userReducer;
    return <Route
      {...rest}
      render={props => user? 
      <Component {...props} role={user.role} />
      :
      <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location }
      }}
      />
      }
    />
  };
  return (
  <div>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          <title>{getBrandConfig(BRAND_CONFIG_KEYS.TITLE)}</title>
          <link rel="icon" href={getBrandConfig(BRAND_CONFIG_KEYS.FAVICON)} />
        </Helmet>
        <Router>
          <Switch>
            <AdminRoute path="/dashboard" component={Dashboard} />                            
            <AdminRoute path="/customer-wallet" component={WalletPage} />                            
            <Route path="/login" component={Login} />
            <Route path="/" component={Login} />
          </Switch>
        </Router>
      </PersistGate>            
    </Provider>
  </div>
  );
}

export default App;
